<template>
  <div>
    <b-modal v-model="show" centered hide-footer hide-header size="lg" id="modal-plan">
      <feather-icon icon="XIcon" @click="show = false" class="x-icon-close" size="20" v-if="selected_plan"></feather-icon>
      <div
        v-if="selected_plan"
        :class="`${selected_plan.color_class} p-2 text-white`"
      >
        <div>
          <h1 class="title-modal">{{ Object.keys(texts).length > 0 ? texts.title : selected_plan.display_name }}</h1>
          <span class="d-flex text-center m-2">{{  Object.keys(texts).length > 0 ? texts.subtitle : selected_plan.description }}</span>
        </div>

        <div class="d-flex justify-content-center m-1">
          {{ $t('creator.annual') }} <b-form-checkbox class="ml-1 custom-switch-blue" switch v-model="anual_plan"></b-form-checkbox> {{ $t('creator.month') }}
        </div>

        <div :class="`${selected_plan.color_class}-badge`"> 
          <b-form-radio-group v-model="selected_plan" class="d-flex justify-content-center m-1" id="ratio-style">
            <b-form-radio v-for="(plan, index_plan) in plans" :key="index_plan" :value="plan" :class="{'border-right': index_plan < plans.length - 1}">
              <div class="d-flex flex-column text-white mr-2">
                <span>{{ plan.display_name }}</span>
                <span v-if="anual_plan"><span class="custom-price">$ {{ plan.price_month.replace('.00', '') }} </span> USD /{{ $t('creator.toMonth') }}</span>
                <span v-else><span class="custom-price">$ {{ plan.price_year.replace('.00', '') }} </span>/{{ $t('creator.toYear') }}</span>
                <span> <b-icon icon="check2" class="icon-check"></b-icon> {{ numberFormat(plan.credit_ai) }} {{ $t('creator.credits') }}</span>
              </div>
            </b-form-radio>
          </b-form-radio-group>
        </div>  


        <div v-if="selected_plan" class="my-2" v-bind:style="{ columnCount: 2 }">
          <div v-for="(element, index) in selected_plan.main_elements" :key="index">
            <small><b-icon icon="check2" class="icon-check"></b-icon>{{ element.text }}</small>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-center"> 
          <b-button variant="light" @click="makeCheckout(anual_plan ? selected_plan.price_month : selected_plan.price_year , selected_plan.uuid)">{{ $t('creator.updated_at') }} {{ selected_plan.display_name }}</b-button>
          <p class="displayed-text">* {{ $t('pricesInMXM') }}</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BFormRadioGroup, BFormRadio, BIcon, BButton, BFormCheckbox, } from "bootstrap-vue";
import service from "@/services/proposals";
import { numberFormat } from '@/libs/utils/formats';
import { getUserData } from '@/libs/utils/user';

export default {
  name: 'ModalMembershipGroup',
  components: {
    BButton,
    BIcon,
    BFormRadio,
    BModal,
    BFormRadioGroup,
    BFormCheckbox,
  },
  props: {
    open_modal_plans_group: {
      type: Boolean,
      default: false,
    },
    texts: {
      type: Object,
      default: () => {
        return {}
      },
    },
    month_dad: {
      type: Boolean,
      default: false
    },
    number_plan_selected: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      numberFormat,
      plans: {},
      show: this.open_modal_plans_group,
      selected_plan: null,
      anual_plan: this.month_dad,
    }
  },
  created() {
    if (getUserData()) this.getPlansMembers();
  },
  methods: {
    makeCheckout(price, uuid) {
      const interval = this.getInterval(price)
      service.checkoutPlan(interval, uuid).then((response) => {
        window.location.href = response.url;
      });
    },
    getInterval(price) {
      return Object.entries(this.selected_plan).find(i => i[1] === price)[0].split('_')[1];
    },
    getPlansMembers() {
      service.getPlan().then((response) => {
        this.plans = response.results.filter(plan => plan.name.toLowerCase() !== 'free');
        this.selected_plan = this.plans[this.number_plan_selected];
      })
    },

  },
};
</script>
<style scope lang="scss">

.custom-switch-blue .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0096fe;
}

.title-modal {
  color: white !important;
  text-align: center;
  margin: 1em 0 0.5em;
  font-weight: bolder;
}
.icon-check {
  font-size: 17px;
}
.color-bg-marketer-plus {
  background: #5675b8;
  background: linear-gradient(90deg, #5675b8 0%, #05a3bc 100%);
  border-radius: 1em;

  .color-bg-marketer-plus-badge {
    padding: 2em 0.5em;
    border-radius: 1em;
    background: #c9c5f359;
  }
}
.color-bg-pro {
  background: rgb(255,255,255);
  background: rgb(105, 71, 188);
  background: linear-gradient(120deg, rgb(105, 71, 188), rgb(153, 62, 144));
  border-radius: 1em;

  .color-bg-pro-badge {
    padding: 2em 0.5em;
    border-radius: 1em;
    background: #fcd9c849;
  }
}
.color-bg-marketer {
  background: linear-gradient(225deg,#068eb5 0%,#9b51e0 47%);
  border-radius: 1em;

  .color-bg-marketer-badge {
    padding: 2em 0.5em;
    border-radius: 1em;
    background: #e6caff59;
  }
}
.container-color-modal-ia {
  background: linear-gradient(225deg,rgba(6,142,181) 0%,rgb(155,81,224) 47%);
  border-radius: 1em;
}
#modal-plan .modal-body {
  padding: 0 !important;
}
#modal-plan .modal-content {
  border-radius: 1em;
}
.displayed-text {
  text-align: center;
  margin: 1em;
  font-size: 11px;
}
.custom-price {
  font-weight: 700;
  font-size: 18px;
}
.badge-price-modal-pro {
  padding: 2em 0.5em;
  border-radius: 1em;
  background: #8B63AF;
}
.badge-price-modal-ia {
  padding: 2em 0.5em;
  border-radius: 1em;
  background: #cccccc5b;
}
#ratio-style .custom-control-input {
  top: 1.5rem;
}
#ratio-style .custom-control-label::before {
  border-color: white;
  background-color: transparent !important;
  position: absolute;
  top: 1.5rem;
}
#ratio-style .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white !important;
  border-radius: 10em;
  position: absolute;
  top: 1.5rem;
}

.x-icon-close {
  position: absolute;
  color: white;
  cursor: pointer;
  top: 1em;
  right: 1em;
}
</style>
